import { render, staticRenderFns } from "./MB.vue?vue&type=template&id=52d4a8d6&scoped=true"
import script from "./MB.vue?vue&type=script&lang=js"
export * from "./MB.vue?vue&type=script&lang=js"
import style0 from "./MB.vue?vue&type=style&index=0&id=52d4a8d6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d4a8d6",
  null
  
)

export default component.exports